import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import * as Icon from 'react-feather'
import PropTypes from 'prop-types'

import { withLabel } from '../../../hoc/withLabel/withLabel'
import { defaultDisplayHeaderDate } from '../../../shared/dateTimeHelper'

const DateRangePicker = ({
  startDate = null,
  endDate = null,
  onChange = null,
  isClearable = true,
}) => {
  const [startDatePicker, setStartDate] = useState(new Date())
  const [endDatePicker, setEndDate] = useState(new Date())

  useEffect(() => {
    setStartDate(startDate)
  }, [startDate])

  useEffect(() => {
    setEndDate(endDate)
  }, [endDate])

  const handleChange = (date, type = 'start') => {
    if (type === 'start') {
      setStartDate(date)
    } else {
      setEndDate(date)
    }

    if (typeof onChange === 'function') {
      onChange({
        startDate: type === 'start' ? date : startDatePicker,
        endDate: type === 'end' ? date : endDatePicker,
      })
    }
  }

  const handleClear = () => {
    setStartDate(null)
    setEndDate(null)

    if (typeof onChange === 'function') {
      onChange({
        startDate: null,
        endDate: null,
      })
    }
  }

  return (
    <div className="textbox-form-container input-group position-relative bg-light">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Calendar className="feather" />
        </span>
      </div>
      <DatePicker
        className="form-control"
        dateFormat="dd MMM yyyy"
        selected={startDatePicker}
        onChange={(date) => handleChange(date, 'start')}
        selectsStart
        placeholderText="Start Date"
        startDate={startDatePicker}
        endDate={endDatePicker}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
          return (
            <div className="d-flex align-items-center justify-content-center mb-3 header-date-picker-custom">
              <div className="d-flex mr-auto">
                <button
                  type="button"
                  className="btn btn-icon"
                  onClick={decreaseMonth}
                >
                  <Icon.ChevronLeft className="feather" />
                </button>
              </div>
              <div className="d-flex">
                <span className="font-weight-bold header-title-current-month">
                  {defaultDisplayHeaderDate(date)}
                </span>
              </div>
              <div className="d-flex ml-auto">
                <button
                  type="button"
                  className="btn btn-icon"
                  onClick={increaseMonth}
                >
                  <Icon.ChevronRight className="feather" />
                </button>
              </div>
            </div>
          )
        }}
        renderDayContents={(day) => <span>{day}</span>}
        showDisabledMonthNavigation
      />
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ padding: '0 8px', zIndex: 0 }}
      >
        <span>-</span>
      </div>
      <DatePicker
        className="form-control"
        dateFormat="dd MMM yyyy"
        selected={endDatePicker}
        onChange={(date) => handleChange(date, 'end')}
        selectsEnd
        placeholderText="End Date"
        startDate={startDatePicker}
        endDate={endDatePicker}
        minDate={startDatePicker}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
          return (
            <div className="d-flex align-items-center justify-content-center mb-3 header-date-picker-custom">
              <div className="d-flex mr-auto">
                <button
                  type="button"
                  className="btn btn-icon"
                  onClick={decreaseMonth}
                >
                  <Icon.ChevronLeft className="feather" />
                </button>
              </div>
              <div className="d-flex">
                <span className="font-weight-bold header-title-current-month">
                  {defaultDisplayHeaderDate(date)}
                </span>
              </div>
              <div className="d-flex ml-auto">
                <button
                  type="button"
                  className="btn btn-icon"
                  onClick={increaseMonth}
                >
                  <Icon.ChevronRight className="feather" />
                </button>
              </div>
            </div>
          )
        }}
        renderDayContents={(day) => <span>{day}</span>}
        showDisabledMonthNavigation
      />
      {isClearable ? (
        <div className="input-group-append">
          <span
            className="input-group-text"
            style={{ paddingLeft: '8px', paddingRight: '8px', zIndex: 0 }}
          >
            <button
              type="button"
              className={`btn btn-icon btn-icon-danger ${
                startDate === null && endDate === null ? 'btn-hide' : ''
              }`}
              onClick={handleClear}
            >
              <Icon.X className="feather" />
            </button>
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

DateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default withLabel(DateRangePicker)
