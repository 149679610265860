import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Loadable from 'react-loadable'

import * as Router from '../../../shared/router'
import { getCurrentView } from '../../../shared/utility'
import { LoadingContainer } from '../../../components/Loader/LoadableLoading/LoadableLoading'
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop'

const CreateOrderContainer = Loadable({
  loader: () => import('../..//CreateOrder/CreateOrderContainer'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const CloneOrderContainer = Loadable({
  loader: () => import('../../CreateOrder/CloneOrderContainer'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const TeamMember = Loadable({
  loader: () => import('../../TeamMember/TeamMember'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
// const OrderBoards = Loadable({
//   loader: () => import('../../OrderBoards/OrderBoards'),
//   loading: LoadingContainer,
//   delay: 300,
//   timeout: 10000,
// });
const DesignOrderBoard = Loadable({
  loader: () => import('../../KanbanOrder/KanbanOrder'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const ClientBoard = Loadable({
  loader: () => import('../../ClientBoard/ClientBoard'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const GeneralSetting = Loadable({
  loader: () => import('../../GeneralSetting/GeneralSetting'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const ManageProfile = Loadable({
  loader: () => import('../../ManageProfile/ManageProfile'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const PaygCustomerInfo = Loadable({
  loader: () => import('../../PaygCustomerInfo/PaygCustomerInfo'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const PaymentMethod = Loadable({
  loader: () => import('../../PaymentMethod/PaymentMethod'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const ManageInvoice = Loadable({
  loader: () => import('../../ManageInvoice/ManageInvoice'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
// const ManageService = Loadable({
//   loader: () => import('../../ManageService/ManageService'),
//   loading: LoadingContainer,
//   delay: 300,
//   timeout: 10000,
// });
const MyCart = Loadable({
  loader: () => import('../../MyCart/MyCart'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const Checkout = Loadable({
  loader: () => import('../../Checkout/Checkout'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
// const ManageStorage = Loadable({
//   loader: () => import('../../ManageStorage/ManageStorage'),
//   loading: LoadingContainer,
//   delay: 300,
//   timeout: 10000,
// });
const ManagePackage = Loadable({
  loader: () => import('../../ManagePackage/ManagePackage'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
// const ManageAllocatedHours = Loadable({
//   loader: () => import('../../ManageAllocatedHours/ManageAllocatedHours'),
//   loading: LoadingContainer,
//   delay: 300,
//   timeout: 10000,
// });
const CheckoutInvoice = Loadable({
  loader: () => import('../../Checkout/CheckoutInvoice'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})
const ArchivedOrder = Loadable({
  loader: () => import('../../ArchivedOrder/ArchivedOrder'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const MyImages = Loadable({
  loader: () => import('../../MyImages/MyImages'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const Retail = Loadable({
  loader: () => import('../../ManageRetail/ManageRetail'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const OldDesignOrderBoard = Loadable({
  loader: () => import('../../DesignOrderBoard/DesignOrderBoard'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const OrderListView = Loadable({
  loader: () => import('../../ListViewOrder/ListViewOrder'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const Payg = Loadable({
  loader: () => import('../../ManagePayg/ManagePayg'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const CartCheckout = Loadable({
  loader: () => import('../../Checkout/CheckoutProcess'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const WorkflowAndRole = Loadable({
  loader: () => import('../../WorkflowAndRole/WorkflowAndRole'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const OperatorReporting = Loadable({
  loader: () => import('../../OperatorReporting/OperatorReporting'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

const OrderReporting = Loadable({
  loader: () => import('../../OrderReporting/OrderReporting'),
  loading: LoadingContainer,
  delay: 300,
  timeout: 10000,
})

function Main({ user }) {
  return (
    <>
      <DragAndDrop>
        <main className="main">
          <Switch>
            {/* <Route path={Router.manageAllocatedHours} exact component={ManageAllocatedHours} /> */}
            <Route
              path={Router.managePackage}
              exact
              component={ManagePackage}
            />
            {/* <Route path={Router.manageStorage} exact component={ManageStorage} /> */}
            {/* <Route path={Router.manageService} exact component={ManageService} /> */}
            <Route path={Router.cart} exact component={MyCart} />
            <Route path={Router.cartCheckout} exact component={CartCheckout} />
            <Route path={Router.checkoutProcess} exact component={Checkout} />
            <Route
              path={Router.checkoutProcessInvoice}
              exact
              component={CheckoutInvoice}
            />
            <Route path={Router.paymentMethod} component={PaymentMethod} />
            <Route path={Router.invoices} component={ManageInvoice} />
            <Route path={Router.teamMember} component={TeamMember} />
            <Route
              path={Router.generalSetting}
              exact
              component={GeneralSetting}
            />
            <Route
              path={Router.manageProfile}
              exact
              component={ManageProfile}
            />
            <Route
              path={Router.paygCustomerInfo}
              exact
              component={PaygCustomerInfo}
            />
            {/* <Route path={Router.orderDetail} exact component={OrderBoards} /> */}
            <Route
              path={Router.orderDetail}
              exact
              component={DesignOrderBoard}
            />
            <Route
              path={Router.createOrder}
              exact
              component={CreateOrderContainer}
            />
            <Route
              path={Router.cloneOrder}
              exact
              component={CloneOrderContainer}
            />
            {/* <Route path={Router.home} exact component={OrderBoards} /> */}
            <Route
              path={Router.home}
              exact
              component={
                getCurrentView(user) === 'client'
                  ? ClientBoard
                  : DesignOrderBoard
              }
            />
            <Route path={Router.createCustomer} exact component={ClientBoard} />
            <Route path={Router.customerDetail} exact component={ClientBoard} />
            <Route
              path={Router.archivedOrder}
              exact
              component={ArchivedOrder}
            />
            <Route path={Router.myImages} exact component={MyImages} />

            <Route path={Router.retail} exact component={Retail} />
            <Route path={Router.workflowAndRoles} component={WorkflowAndRole} />
            <Route path={Router.oldKanban} component={OldDesignOrderBoard} />
            <Route
              path={Router.orderListView}
              exact
              component={OrderListView}
            />
            <Route path={Router.payg} exact component={Payg} />
            <Route
              path={Router.operatorReporting}
              exact
              component={OperatorReporting}
            />
            <Route
              path={Router.orderReporting}
              exact
              component={OrderReporting}
            />
          </Switch>
        </main>
      </DragAndDrop>
    </>
  )
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  }
}

export default connect(mapStateToProps)(Main)
